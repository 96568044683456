import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import plusIcon from "assets/icons/plus.svg"
import userIcon from "assets/icons/user.svg"
import Logo from "modules/components/Logo/Logo"
import SearchResult from "modules/components/SearchResult/SearchResult"
import { POST } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useMainContext from "modules/Core/hooks/useMainContext"
import { Container } from "styles/Global.styled"
import { CategoryType, SearchResultType } from "types/common"
import { DOWNLOAD_JBF_ID } from "utils/helpers/variables"

import {
    Categories,
    CategoriesList,
    List,
    Navbar,
    NavbarButton,
    NavbarItem,
    SearchInput,
    SearchInputButton,
    StyledLargeNavbar,
    ShowFeedbackButton,
    ShowFeedbackIcon,
    BackgroundBlur,
} from "./LargeNavbar.styled"

import useShowResults from "../../hooks/useShowResults"
import FeedbackModal from "../FeedbackModal/FeedbackModal"

const LargeNavbar = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { categoryList } = useMainContext()

    const [showCategoriesContext, setShowCategoriesContext] = useState(false)
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)
    const [searchResults, setSearchResults] = useState<SearchResultType[]>([])

    const { showResults, setShowResults } = useShowResults(searchResults)

    const handleShowCategoriesContext = () => {
        setShowCategoriesContext(true)
    }

    const handleHideCategoriesContext = () => {
        setShowCategoriesContext(false)
    }

    const handleSearchInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget

        if (!value) {
            setSearchResults([])
            return
        }

        try {
            const response = await POST("/api/search/by/text", { q: value })
            if (response.data && response.data.data) {
                const { posts } = response.data.data
                setSearchResults(posts)
            }
        } catch (err) {
            setSearchResults([])
        }
    }

    return (
        <>
            <StyledLargeNavbar>
                <Container>
                    <Navbar>
                        <NavbarItem gap="1.875rem">
                            <Logo onMouseOver={handleHideCategoriesContext} />
                            <Categories onMouseOver={handleShowCategoriesContext}>
                                <i />
                                Категории
                            </Categories>
                            {showCategoriesContext && (
                                <CategoriesList>
                                    <List>
                                        {categoryList &&
                                            categoryList
                                                .filter(
                                                    (item: CategoryType) =>
                                                        item.id !== DOWNLOAD_JBF_ID
                                                )
                                                .map((item: CategoryType) => (
                                                    <li key={item.id}>
                                                        <Link to={`catalogue/${item.id}`}>
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                    </List>
                                </CategoriesList>
                            )}
                        </NavbarItem>
                        <NavbarItem onMouseOver={handleHideCategoriesContext}>
                            <SearchInput
                                type="text"
                                placeholder="Введите нужный запрос..."
                                onChange={handleSearchInput}
                                onFocus={handleSearchInput}
                            />
                            <SearchInputButton>
                                <i />
                            </SearchInputButton>

                            {!showCategoriesContext && showResults && (
                                <SearchResult data={searchResults} />
                            )}
                        </NavbarItem>
                        <NavbarItem gap="0.9375rem" onMouseOver={handleHideCategoriesContext}>
                            <NavbarButton
                                icon={plusIcon}
                                backgroundColor="main"
                                onClick={() => navigate("/post/new")}
                            >
                                <i />
                                Подать объявление
                            </NavbarButton>
                            {!auth.accessToken ? (
                                <NavbarButton icon={userIcon} onClick={() => navigate("/auth")}>
                                    <i />
                                    Войти
                                </NavbarButton>
                            ) : (
                                <NavbarButton
                                    icon={userIcon}
                                    onClick={() => navigate("/cabinet/profile")}
                                >
                                    <i />
                                    Профиль
                                </NavbarButton>
                            )}
                        </NavbarItem>
                    </Navbar>
                </Container>
            </StyledLargeNavbar>

            <ShowFeedbackButton onClick={() => setShowFeedbackModal(true)}>
                <ShowFeedbackIcon />
            </ShowFeedbackButton>

            {showFeedbackModal && <FeedbackModal handleClose={() => setShowFeedbackModal(false)} />}

            {(showCategoriesContext || showResults) && (
                <BackgroundBlur
                    onMouseOver={handleHideCategoriesContext}
                    onClick={() => setShowResults(false)}
                />
            )}
        </>
    )
}

export default LargeNavbar
