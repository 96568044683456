import styled from "styled-components"

export const StyledSearchResult = styled.div`
    position: absolute;
    top: 3.5rem;
    right: 0;
    z-index: 998;
`

export const ResultList = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    border-radius: ${({ theme }) => theme.radius.small};

    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.colors.black};
    min-width: 100%;

    max-height: 18.75rem;
    overflow: auto;
`

export const ResultItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1rem;
    gap: 0.3125rem;

    max-height: 50%;

    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.main};
        border-radius: ${({ theme }) => theme.radius.small};
        cursor: pointer;
    }
`

export const PostDescription = styled.div`
    font-size: 0.875rem;
    line-height: 1rem;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    overflow: hidden;
`

export const PostCategory = styled.div`
    font-size: 0.6rem;
    line-height: 0.5625rem;
`

export const Line = styled.hr`
    width: 100%;

    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrayHr};
    padding: 0;
`
