/*eslint-disable*/
import { ErrorMessage, Field, Form, Formik } from "formik"

import {
    AdminForm,
    AdminFormButtons,
    AdminFormItem,
} from "pages/AdminPanelPage/modules/helpers/components/AdminForm/AdminForm.styled"
import Required from "pages/AdminPanelPage/modules/helpers/components/Required/Required"
import { UserType } from "types/common"
import { Button } from "ui/Button/Button.styled"
import { Input } from "ui/Input/Input.styled"
import { renderError } from "utils/helpers"
import { InputComponent, SelectСomponent } from "utils/helpers/components/FormikField"
import * as Yup from "yup"
import { checkForNull, getUserStatusText, userStatusOptions } from "../utils"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { toast } from "react-toastify"
import { POST_FORM } from "modules/Core/api/REST"
import { useState } from "react"

interface CreateUserFormProps {
    setCurrentState: React.Dispatch<React.SetStateAction<string>>
    userEdit?: UserType
}

interface FormFields {
    email: string
    fio: string
    phone: string
    user_status: string
}

const validationSchema = Yup.object({
    fio: Yup.string().required("Заполните ФИО пользователя"),
    user_status: Yup.string().required("Выберите статус"),
    phone: Yup.string()
        .matches(
            /^((\+7|8)\d{10})$/g,
            "Введите корректный номер телефона(без пробелов и допонительных символов)"
        )
        .required("Номер телефона обязательное поле"),
})

const CreateUserForm: React.FC<CreateUserFormProps> = ({ setCurrentState, userEdit }) => {
    const headers = useAuthorizationHeader()
    const [selectedFile, setSelectedFile] = useState<File>()
    console.log("userEdit", userEdit)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files

        if ((!files || files.length === 0) && !selectedFile) {
            setSelectedFile(undefined)
        } else if (files) {
            const file = files[0]
            setSelectedFile(file)
        }
    }

    const handleSubmit = async (values: FormFields) => {
        console.log("submit", values)

        try {
            const formData = new FormData()

            formData.append("email", values.email)
            formData.append("fio", values.fio)
            formData.append("phone", values.phone)

            if (values.user_status === "archive") {
                formData.append("is_archive", "true")
            } else if (values.user_status === "inactive") {
                formData.append("is_archive", "false")
                formData.append("is_active", "false")
            } else if (values.user_status === "active") {
                formData.append("is_archive", "false")
                formData.append("is_active", "true")
            }

            if (selectedFile && typeof selectedFile !== "string")
                formData.append("icon", selectedFile)

            const { data } = await POST_FORM("/api/admin/users/" + userEdit?.id, formData, headers)

            if (data.ok === 1) {
                toast.success("Пользователь успешно обновлен!")
            } else {
                throw new Error(JSON.stringify(data?.detail))
            }
        } catch (error) {
            toast.error("Произошла ошибка" + error)
        }
    }

    const FIO = [
        checkForNull(userEdit?.surname),
        checkForNull(userEdit?.first_name),
        checkForNull(userEdit?.patronymic),
    ]

    const initialValues = {
        email: userEdit?.email || "",
        fio: FIO.join(" ").trim() || "",
        phone: userEdit?.phone || "",
        user_status: getUserStatusText(userEdit as UserType) || "",
    }

    console.log(initialValues)

    return (
        <AdminForm>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
            >
                <Form>
                    <AdminFormItem>
                        <label htmlFor="">
                            Email: <Required />
                        </label>
                        <div>
                            <Field name="email" component={InputComponent} />
                            <ErrorMessage name="email" render={renderError} />
                        </div>
                    </AdminFormItem>
                    <AdminFormItem>
                        <label htmlFor="">
                            Ф.И.О: <Required />
                        </label>
                        <div>
                            <Field name="fio" component={InputComponent} />
                            <ErrorMessage name="fio" render={renderError} />
                        </div>
                    </AdminFormItem>
                    <AdminFormItem>
                        <label htmlFor="">
                            Телефон: <Required />
                        </label>
                        <div>
                            <Field name="phone" component={InputComponent} />
                            <ErrorMessage name="phone" render={renderError} />
                        </div>
                    </AdminFormItem>
                    <AdminFormItem>
                        <label htmlFor="">
                            Статус пользователя: <Required />
                        </label>
                        <div>
                            <Field
                                name="user_status"
                                component={SelectСomponent}
                                placeholder="Выберите статус"
                                options={userStatusOptions}
                            />
                            <ErrorMessage name="user_status" render={renderError} />
                        </div>
                    </AdminFormItem>
                    <AdminFormItem>
                        <label htmlFor="">Фото:</label>
                        <Input type="file" onChange={handleFileChange} accept="image/*" />
                    </AdminFormItem>
                    {userEdit?.image && (
                        <AdminFormItem>
                            <label htmlFor="">Текущая фотография:</label>
                            <img src={userEdit?.image} width={300} />
                        </AdminFormItem>
                    )}
                    <AdminFormButtons>
                        <Button
                            type="button"
                            btnColor="darkestGray"
                            onClick={() => setCurrentState("list")}
                        >
                            Вернуться к списку
                        </Button>
                        <Button type="submit">Сохранить</Button>
                    </AdminFormButtons>
                </Form>
            </Formik>
        </AdminForm>
    )
}

export default CreateUserForm
