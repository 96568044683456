import { Formik, Form, Field, ErrorMessage } from "formik"
import { useContext, useState } from "react"
import { Link, useParams } from "react-router-dom"
import * as Yup from "yup"

import { POST } from "modules/Core/api/REST"
import { AuthContent, FormItem, TextContainer } from "pages/AuthPage/AuthPage.styled"
import { Button } from "ui/Button/Button.styled"
import { renderError } from "utils/helpers"
import { InputComponent } from "utils/helpers/components/FormikField"

import { AuthFormContext } from "../../context/AuthFormContext"
import Password from "../Password/Password"

interface ResetPassword {
    password: string
    confirmPassword: string
}

const initialValues = {
    password: "",
    confirmPassword: "",
}

const validationSchema = Yup.object({
    password: Yup.string()
        .required("Введите пароль (минимум 8 символов)")
        .min(8, "Минимум 8 символов"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Введенные пароли не совпадают!"
    ),
})

const ResetPassword = () => {
    const authContext = useContext(AuthFormContext)
    const { uuid } = useParams()
    console.log(uuid)
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (values: ResetPassword) => {
        authContext?.setErrorMessage("")
        setIsLoading(true)
        handleRequest(values.password)
    }

    const handleRequest = async (password: string) => {
        try {
            const { data: response } = await POST(`/api/accounts/password-confirm/${uuid}`, {
                password,
            })

            if (response.ok === 1) {
                setSuccess(true)
            } else {
                authContext?.setErrorMessage(
                    "Непредвиденная ошибка при смене пароля! Попробуйте позднее " +
                        String(response.detail)
                )
            }
        } catch (err) {
            authContext?.setErrorMessage(
                "Непредвиденная ошибка при смене пароля! Попробуйте позднее"
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={handleSubmit}
        >
            <Form>
                {success && (
                    <TextContainer align="center" color="green">
                        Пароль успешно изменен! Перейдите на страницу{" "}
                        <Link to="/auth">авторизации</Link> и выполните вход
                    </TextContainer>
                )}
                <AuthContent>
                    <FormItem>
                        <label htmlFor="">Пароль</label>
                        <Password>
                            <Field
                                name="password"
                                type={authContext?.showPassword ? "text" : "password"}
                                component={InputComponent}
                            />
                        </Password>
                        <ErrorMessage name="password" render={renderError} />
                    </FormItem>

                    <FormItem>
                        <label htmlFor="">Повторите пароль</label>
                        <Password>
                            <Field
                                name="confirmPassword"
                                type={authContext?.showPassword ? "text" : "password"}
                                component={InputComponent}
                            />
                        </Password>

                        <ErrorMessage name="confirmPassword" render={renderError} />
                    </FormItem>
                </AuthContent>

                <Button type="submit" style={{ marginTop: "1rem" }} disabled={isLoading || success}>
                    Изменить пароль
                </Button>
            </Form>
        </Formik>
    )
}

export default ResetPassword
