import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { GET } from "modules/Core/api/REST"
import useAuth from "modules/Core/hooks/useAuth"
import useAuthorizationHeader from "modules/Core/hooks/useAuthorizationHeader"
import { PostDetailsType } from "types/common"

const usePostDetails = (id: number) => {
    const [postDetails, setPostDetails] = useState<PostDetailsType>()
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const headers = useAuthorizationHeader()
    const { auth } = useAuth()
    console.log(auth)

    const fGetPostDetail = async () => {
        try {
            const { data } = auth?.user
                ? await GET(`/api/service/profile/${id}/`, {}, { headers })
                : await GET(`/api/service/profile/${id}/`)

            if (data && data.data) {
                setPostDetails(data?.data.data)
            } else {
                navigate("/")
            }
        } catch (err) {
            navigate("/")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fGetPostDetail()
    }, [id])

    return { postDetails, isLoading }
}
export default usePostDetails
